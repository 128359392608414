import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { HTMLContent } from "../../components/Content";
import AboutPageTemplate from "../../components/AboutPageTemplate";
import Layout from "../../components/Layout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>{data.allContentfulAboutUs.edges[0].node.metaTitle}</title>
        <meta
          name="description"
          content={data.allContentfulAboutUs.edges[0].node.metaDescription}
        />
      </Helmet>
      <AboutPageTemplate
        title={data.allContentfulAboutUs.edges[0].node.title}
        content={documentToReactComponents(
          data.allContentfulAboutUs.edges[0].node.content.json
        )}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query aboutus {
    allContentfulAboutUs {
      edges {
        node {
          title
          metaDescription
          metaTitle
          content {
            json
          }
        }
      }
    }
  }
`;
